@import './layout.scss';

@font-face {
  font-family: 'Boheme Floral';
  src: url('fonts/Boheme Floral.ttf');
}

@font-face {
  font-family: 'Darling';
  src: url('fonts/Darling.ttf');
}

@font-face {
  font-family: 'HelloMorgan';
  src: url('fonts/HelloMorgan.ttf');
}

html,
body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
  display: flex;
}
