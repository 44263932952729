.hotel {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  align-items: center;

  &__name {
    font-weight: bold;
  }
}