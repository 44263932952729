@import '../layout.scss';

.names {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto auto auto auto 1fr;

  justify-items: center;

  font-family: 'Boheme Floral', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 64px;
  color: white;

  background-image: url('../images/drumore-fall-chapel.jpg');
  // background-image: url('../images/vandiver-lighthouse.jpg');
  // background-position: 50% 70%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  padding-bottom: 50px;

  @include sm() {
    min-height: 500px;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: auto auto auto auto 1fr;
    row-gap: 40px;
    padding: 0;
    font-size: 128px;
  }

  .names__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 1;
  }

  .names__name {
    display: inline;
    z-index: 2;

    &:nth-child(3) {
      margin: 0 50px;
      font-size: 70px;

      @include sm() {
        margin-top: 20px;
      }
    }

    @include sm {
      &:nth-child(2) {
        width: 100%;
        text-align: right;
      }

      &:nth-child(4) {
        width: 100%;
        text-align: left;
      }
    }
  }

  .names__date {
    font-size: 32px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    z-index: 2;
    margin-top: 20px;

    @include sm {
      grid-column: 1 / span 3;
      margin-top: 0;
    }
  }

  .names__location {
    font-size: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    z-index: 2;
    margin-top: 0px;

    @include sm {
      grid-column: 1 / span 3;
      margin-top: -40px;
    }
  }

  .names__header {
    font-size: 32px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    z-index: 2;
    font-weight: normal;
    text-transform: uppercase;


    font-size: 40px;
    // letter-spacing: 1px;

    height: 50px;

    margin-top: 20px;
    margin-bottom: 0;

    @include xxxs {
      height: 60px;
    }

    @include xxs {
      height: 70px;
    }

    @include xs {
      height: 80px;
    }

    @include sm {
      grid-column: 1 / span 3;
      margin-bottom: -40px;
    }

    path {
      fill: transparent;
    }
  }

  .names__std {
    font-size: 32px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    z-index: 2;
    font-weight: normal;
    text-transform: uppercase;

    // font-size: 59px;
    font-size: 22px;
    letter-spacing: 1px;

    height: 40px;

    margin-top: 20px;

    @include xs {
      // height: 50px;
      font-size: 28px;
    }

    @include sm {
      grid-column: 1 / span 3;
      margin-top: 0;
    }

    // path {
    //   //fill: transparent;
    // }
  }
}
