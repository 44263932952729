.registry {
  padding: 10px;

  font-size: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__heading {
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 20px;
  }

}