@font-face {
  font-family: Boheme Floral;
  src: url("Boheme Floral.e73c4da9.ttf");
}

@font-face {
  font-family: Darling;
  src: url("Darling.98c9646a.ttf");
}

@font-face {
  font-family: HelloMorgan;
  src: url("HelloMorgan.544dc2e3.ttf");
}

html, body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

#root {
  min-height: 100vh;
  display: flex;
}

.names {
  color: #fff;
  background-image: url("drumore-fall-chapel.c4e81aad.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  grid-template-rows: auto auto auto auto auto auto auto 1fr;
  grid-template-columns: 1fr;
  justify-items: center;
  padding-bottom: 50px;
  font-family: Boheme Floral, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 64px;
  display: grid;
  position: relative;
}

@media screen and (min-width: 720px) {
  .names {
    min-height: 500px;
    grid-template-rows: auto auto auto auto 1fr;
    grid-template-columns: 1fr auto 1fr;
    row-gap: 40px;
    padding: 0;
    font-size: 128px;
  }
}

.names .names__overlay {
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #00000080;
  position: absolute;
  top: 0;
  left: 0;
}

.names .names__name {
  z-index: 2;
  display: inline;
}

.names .names__name:nth-child(3) {
  margin: 0 50px;
  font-size: 70px;
}

@media screen and (min-width: 720px) {
  .names .names__name:nth-child(3) {
    margin-top: 20px;
  }
}

@media screen and (min-width: 720px) {
  .names .names__name:nth-child(2) {
    width: 100%;
    text-align: right;
  }

  .names .names__name:nth-child(4) {
    width: 100%;
    text-align: left;
  }
}

.names .names__date {
  z-index: 2;
  margin-top: 20px;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 32px;
}

@media screen and (min-width: 720px) {
  .names .names__date {
    grid-column: 1 / span 3;
    margin-top: 0;
  }
}

.names .names__location {
  z-index: 2;
  margin-top: 0;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
}

@media screen and (min-width: 720px) {
  .names .names__location {
    grid-column: 1 / span 3;
    margin-top: -40px;
  }
}

.names .names__header {
  z-index: 2;
  text-transform: uppercase;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 0;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 40px;
  font-weight: normal;
}

@media screen and (min-width: 300px) {
  .names .names__header {
    height: 60px;
  }
}

@media screen and (min-width: 400px) {
  .names .names__header {
    height: 70px;
  }
}

@media screen and (min-width: 500px) {
  .names .names__header {
    height: 80px;
  }
}

@media screen and (min-width: 720px) {
  .names .names__header {
    grid-column: 1 / span 3;
    margin-bottom: -40px;
  }
}

.names .names__header path {
  fill: #0000;
}

.names .names__std {
  z-index: 2;
  text-transform: uppercase;
  letter-spacing: 1px;
  height: 40px;
  margin-top: 20px;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 22px;
  font-weight: normal;
}

@media screen and (min-width: 500px) {
  .names .names__std {
    font-size: 28px;
  }
}

@media screen and (min-width: 720px) {
  .names .names__std {
    grid-column: 1 / span 3;
    margin-top: 0;
  }
}

.countdown {
  width: 100%;
  color: #fff;
  flex-direction: column;
  align-items: center;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
}

.countdown__heading {
  text-align: center;
  margin: 50px 0;
  font-size: 20px;
  font-weight: normal;
}

.countdown__times {
  justify-content: center;
  align-items: center;
  display: flex;
}

.countdown__times .times__time {
  width: 60px;
  border: 1px solid #fff;
  border-radius: 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 5px;
  display: inline-flex;
}

@media screen and (min-width: 300px) {
  .countdown__times .times__time {
    width: 62px;
  }
}

@media screen and (min-width: 400px) {
  .countdown__times .times__time {
    width: 90px;
  }
}

@media screen and (min-width: 500px) {
  .countdown__times .times__time {
    width: 100px;
  }
}

.countdown__times .times__time--hidden {
  display: none;
}

.countdown__times .times__time .time__value {
  font-size: 55px;
  font-weight: bold;
}

@media screen and (min-width: 300px) {
  .countdown__times .times__time .time__value {
    font-size: 55px;
  }
}

@media screen and (min-width: 400px) {
  .countdown__times .times__time .time__value {
    font-size: 68px;
  }
}

@media screen and (min-width: 500px) {
  .countdown__times .times__time .time__value {
    font-size: 72px;
  }
}

.countdown__times .times__time .time__unit {
  font-family: HelloMorgan, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
}

@media screen and (min-width: 300px) {
  .countdown__times .times__time .time__unit {
    font-size: 16px;
  }
}

@media screen and (min-width: 400px) {
  .countdown__times .times__time .time__unit {
    font-size: 20px;
  }
}

@media screen and (min-width: 500px) {
  .countdown__times .times__time .time__unit {
    font-size: 22px;
  }
}

.countdown__rsvp {
  margin: 50px 0;
  display: none;
}

.countdown__registry-info {
  margin: 50px 0;
}

.verify {
  flex-direction: column;
  align-items: center;
  padding: 10px;
  display: flex;
}

.verify .verify__submit {
  margin: 10px;
}

.verify__info .info__header {
  margin: 20px 0 5px;
}

.verify__info .info__totp {
  height: 300px;
  background-image: url("totp.82a481ba.jpg");
  background-repeat: no-repeat;
  background-size: contain;
}

.verify__info .info__apps {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 500px) {
  .verify__info .info__apps {
    flex-direction: row;
  }
}

.verify__info .info__apps .apps__android img {
  width: 200px;
}

@media screen and (min-width: 720px) {
  .verify__info .info__apps .apps__android img {
    width: 280px;
  }
}

.verify__info .info__apps .apps__iphone img {
  width: 160px;
}

@media screen and (min-width: 500px) {
  .verify__info .info__apps .apps__iphone {
    margin: 12px;
  }
}

@media screen and (min-width: 720px) {
  .verify__info .info__apps .apps__iphone {
    margin: 15px;
  }

  .verify__info .info__apps .apps__iphone img {
    width: 230px;
  }
}

.hotel {
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  display: flex;
}

.hotel__name {
  font-weight: bold;
}

.accomodations {
  flex-direction: column;
  align-items: center;
  padding: 10px;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  display: flex;
}

.accomodations__heading {
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: bold;
}

.registry {
  flex-direction: column;
  align-items: center;
  padding: 10px;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  display: flex;
}

.registry__heading {
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: bold;
}



/*# sourceMappingURL=index.90acde87.css.map */
