@mixin md {
  @media screen and (min-width: 900px) {
    @content;
  }
}

@mixin sm {
  @media screen and (min-width: 720px) {
    @content;
  }
}

@mixin xs {
  @media screen and (min-width: 500px) {
    @content;
  }
}

@mixin xxs {
  @media screen and (min-width: 400px) {
    @content;
  }
}

@mixin xxxs {
  @media screen and (min-width: 300px) {
    @content;
  }
}
