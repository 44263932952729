@import '../layout.scss';

.verify {
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;

  & &__submit {
    margin: 10px;
  }

  &__info {
    .info__header {
      margin: 20px 0 5px 0;
    }

    .info__totp {
      background-image: url('../images/totp.jpg');
      background-repeat: no-repeat;
      background-size: contain;
      height: 300px;
    }

    .info__apps {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include xs {
        flex-direction: row;
      }

      .apps__android {
        img {
          width: 200px;

          @include sm {
            width: 280px;
          }
        }
      }

      .apps__iphone {
        img {
          width: 160px;
        }

        @include xs {
          margin: 12px;
        }

        @include sm {
          margin: 15px;

          img {
            width: 230px;
          }
        }
      }
    }
  }
}
