@import '../layout.scss';

.countdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  &__heading {
    //text-transform: uppercase;
    font-weight: normal;
    font-size: 20px;
    margin: 50px 0;
    text-align: center;
  }

  &__times {
    display: flex;
    align-items: center;
    justify-content: center;

    .times__time {
      display: inline-flex;
      flex-direction: column;
      margin: 10px;
      width: 60px;
      border: 1px solid white;
      border-radius: 3px;
      align-items: center;
      justify-content: center;
      padding: 5px;

      @include xxxs {
        width: 62px;
      }

      @include xxs {
        width: 90px;
      }

      @include xs {
        width: 100px;
      }

      &--hidden {
        display: none;
      }

      .time__value {
        font-weight: bold;
        font-size: 55px;

        @include xxxs {
          font-size: 55px;
        }

        @include xxs {
          font-size: 68px;
        }

        @include xs {
          font-size: 72px;
        }
      }

      .time__unit {
        font-family: 'HelloMorgan', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 14px;

        @include xxxs {
          font-size: 16px;
        }

        @include xxs {
          font-size: 20px;
        }

        @include xs {
          font-size: 22px;
        }
      }
    }
  }

  &__rsvp {
    margin: 50px 0;
    display: none;
  }

  &__registry-info {
    margin: 50px 0;
  }
}
